<template>
  <v-dialog v-model="showingRipenerModal" fullscreen hide-overlay>
    <v-card>
      <v-card-text v-if="loading">
        Cargando...
        <v-progress-linear
        indeterminate
        color="black"
        class="mb-0"
        ></v-progress-linear>
      </v-card-text>

      <v-toolbar class="tool-bar">
        <v-btn icon color="primary" @click="close()">
        <v-icon>{{icons.mdiClose}}</v-icon>
        </v-btn>
        <v-toolbar-title class="text-h5 grey lighten-2 ">{{`Madurador #${ripener_details.id} - ${ripener_details.name}`}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <div class="d-flex">
        <v-card-subtitle class="pt-4 ml-5">
          {{ripener_details.created_at ? `Creada el ${ripener_details.created_at.slice(0,10)} por el usuario ${ripener_details.created_by}` : ''}}
        </v-card-subtitle>
        <v-spacer></v-spacer>
        <v-card-subtitle class="pt-4 mr-5">
          <div>
            <strong class="mr-1">{{'Mensaje de respuesta: '}}</strong>
            <strong :class="ripener_details.expected_response ? 'primary--text' : 'accent--text'">{{ ripener_details.expected_response ? `"${ripener_details.expected_response}"` : 'Sin especificar'}}</strong>
          </div>
          <div>
            <strong class="mr-1">{{'¿Esperar respuesta para iniciar?'}}</strong>
            <strong :class="ripener_details.wait_answer ? 'success--text' : 'error--text'">{{ ripener_details.wait_answer ? 'Si' : 'No'}}</strong>
          </div>
        </v-card-subtitle>
      </div>

      <v-divider></v-divider>

      <v-card-text v-if="!loading">
        <v-row class="mb-2">

          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar
              size="44"
              color="success"
              rounded
              class="elevation-1"
              >
                <v-icon
                dark
                color="white"
                size="30"
                >
                    {{icons.mdiCheckAll}}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">
                  Total mensajes enviados
                </p>
                <span class="text--primary text-xl font-weight-bold">
                  {{ripener_details.total_message_send}}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar
              size="44"
              color="info"
              rounded
              class="elevation-1"
              >
                <v-icon
                dark
                color="white"
                size="30"
                >
                    {{icons.mdiMessageQuestionOutline}}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">
                  Total mensajes iniciales
                </p>
                <span class="text--primary text-xl font-weight-bold">
                  {{ripener_details.initial_mssgs_total ? ripener_details.initial_mssgs_total : '0'}}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar
              size="44"
              :color="terciaryColor"
              rounded
              class="elevation-1"
              >
                <v-icon
                dark
                color="white"
                size="30"
                >
                    {{icons.mdiMessageProcessingOutline}}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">
                  Mensajes iniciales procesados
                </p>
                <span class="text--primary text-xl font-weight-bold">
                  {{ripener_details.procesed_init_mssgs}}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="3" md="3">
            <div class="d-flex">
              <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
              >
                <v-icon
                dark
                color="white"
                size="30"
                >
                  {{icons.mdiHelpCircleOutline}}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-s mb-0">
                  Estado del madurador
                </p>
                <span class="text--primary text-xl font-weight-bold">
                  {{statusParser[ripener_details.status]}}
                </span>
              </div>
            </div>
          </v-col>

        </v-row>
        <v-divider></v-divider>
      </v-card-text>

      <v-card-text v-if="!loading">
        <v-row>

          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar
              size="44"
              color="accent"
              rounded
              class="elevation-1"
              >
                <v-icon
                dark
                color="white"
                size="30"
                >
                    {{icons.mdiCellphoneWireless}}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <span class="text--primary text-xl font-weight-bold">
                  Canales asociados al madurador
                </span>
                <p v-if="!ripener_details.channel || !ripener_details.channel.length" class="text-s mb-0">No hay canales asociados.</p>
                <div style="overflow-y:auto;max-height: 105px;">
                  <div v-for="(item, idx) in ripener_details.channel" :key="`ripener_channel_item_${idx}`">
                    <p class="text-s mb-0">{{item.name}}</p>
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar
              size="44"
              color="secondary"
              rounded
              class="elevation-1"
              >
                <v-icon
                dark
                color="white"
                size="30"
                >
                  {{icons.mdiCellphoneMessageOff}}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <span class="text--primary text-xl font-weight-bold">
                  Contactos asociados al madurador
                </span>
                <p v-if="!ripener_details.blacknumber || !ripener_details.blacknumber.length" class="text-s mb-0">No hay contactos asociados.</p>
                <div style="overflow-y:auto;max-height: 105px;">
                  <div v-for="(item, idx) in ripener_details.blacknumber" :key="`ripener_blacknumber_item_${idx}`">
                    <p class="text-s mb-0">{{item.name}}</p>
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="4" md="4">
            <div class="d-flex">
              <v-avatar
              size="44"
              color="warning"
              rounded
              class="elevation-1"
              >
                <v-icon
                dark
                color="white"
                size="30"
                >
                  {{icons.mdiCalendarQuestion}}
                </v-icon>
              </v-avatar>

              <div class="ms-3">
                <span class="text--primary text-xl font-weight-bold">
                  Configuración del madurador
                </span>

                <!-- <p class="text-s mb-0">Fecha de inicio: {{ripener_details.start_date}}</p>
                <p class="text-s mb-0">Fecha de finalización: {{ ripener_details.ending_date }}</p>
                <p class="text-s mb-0">Espera máxima: {{ ripener_details.maxim_wait }}</p>
                <p class="text-s mb-0">Espera mínima: {{ ripener_details.minim_wait }}</p> -->
                <p class="text-s mb-0" @click="handleConfigModal('config')"> <a>Ver configuración </a> </p>
                <p class="text-s mb-0" @click="handleConfigModal('date')"> <a>Ver días y horarios</a> </p>
                <span></span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="9" md="9">
            <v-text-field
            v-model="searchQuery"
            @keyup="handleSearch"
            placeholder="Buscar por usuario, canal, cliente, mensaje o teléfono"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
            >
            </v-text-field>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="3" md="3">
            <v-select
            class="mb-4"
            :items="[{name: '-', value: -1}, {name: 'Pendiente', value: 0}, {name: 'Enviado', value: 1}, {name: 'Fallido', value: 2}]"
            hide-details="true"
            v-model="stateFilterValue"
            label="Estado"
            item-text="name"
            item-value="value"
            @change="setFilterStatus"
            outlined
            dense
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="ripener_messages"
        :page="current_page"
        :loading="loadingMsg"
        :server-items-length="totalMessageListTable"
        :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
        @update:page="changingRipenerMsgPage($event)"
        @update:items-per-page="changingRipenerMsgItemsPerPage($event)"
        >
          <!-- ok whatscupon-->
          <template #[`item.validation_ok_whatscupon`]="{item}">
            <v-icon
            class=""
            :color="item.validation_ok_whatscupon ? 'success' : 'error'"
            small
            dark
            >{{ item.validation_ok_whatscupon ? icons.mdiCheckAll : icons.mdiClose}}
            </v-icon>
          </template>
          <!-- user -->
          <template #[`item.user`]="{item}">
            <div >
              {{ item.user }}
            </div>
          </template>

          <!-- channel -->
          <template #[`item.channel`]="{item}">
            <span class="text-capitalize">{{ item.channel }}</span>
          </template>

          <!-- phone -->
          <template #[`item.phone`]="{item}">
            <span class="text-capitalize font-weight-semibold text--primary">{{ item.phone }}</span>
          </template>

          <!-- message -->
          <template #[`item.message`]="{item}">
            <span v-if="item.message.length <= 75">{{ item.message }}</span>
            <span v-else>
              {{ item.message.substring(0, 75) + '...' }}
              <v-btn icon x-small @click="showCompleteMsg(item.message)">
              <v-icon color="primary">{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </span>
          </template>

          <template #[`item.date`]="{item}">
            <span class="text-capitalize font-weight-semibold text--primary">{{ item.send_time != null ? item.send_time.slice(0,10) : '-'  }}</span>
          </template>

          <template #[`item.time`]="{item}">
            <span class="text-capitalize font-weight-semibold text--primary">{{ item.send_time != null ? item.send_time.slice(11, 16) : '-' }}</span>
          </template>

          <template #[`item.status`]="{item}">
            <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ translateStatus(item.status) }}
            </v-chip>
            <v-btn
            icon
            color="error"
            x-small
            @click="openFailedMessageModal(item.id)"
            v-if="item.status === 2"
            >
                <v-icon>{{icons.mdiEyePlusOutline}}</v-icon>
            </v-btn>
          </template>

          <template #[`item.work_status`]="{item}">
            <v-chip
            small
            :color="item.work_status ? 'success' : 'error'"
            :class="`${item.work_status ? 'success' : 'error'}--text`"
            class="v-chip-light-bg font-weight-semibold"
            >
              {{ item.work_status ? 'Está trabajando' : 'Está descansando' }}
            </v-chip>
          </template>

          <!-- files -->
          <template #[`item.files`]="{item}">
            <v-btn v-if="item.files.length && item.files[0].id"
            class="view-files"
            elevation="2"
            x-small
            outlined
            @click="openAttachedFiles(item.files)"
            :plain="attached_items.length === 1 && attached_items[0] == 'disabled'"
            >
            Ver archivos
            </v-btn>

            <div v-else>-</div>
          </template>
      </v-data-table>

      <!-- Show complete Message Text -->
      <template>
        <v-dialog v-model="long_msg_modal" max-width="500">
          <v-card>
          <v-card-text>
            {{ long_msg }}
          </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <div class="text-center">
        <v-dialog
        v-model="attached_files_dialog"
        width="400"
        scrollable
        >

        <!-- Showing if files are outdated -->
        <v-card v-if="attached_items.length === 1 && attached_items[0] == 'disabled'">
          <v-card-title class="text-h5 grey lighten-2">
            Archivos no disponibles
          </v-card-title>

          <v-card-text style="max-height: 300px">
            Los archivos adjuntos solo permanecen guardados durante 7 días.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="attached_files_dialog = false">
              CERRAR
          </v-btn>
          </v-card-actions>
        </v-card>

        <!-- Showing if files still downloadable -->
        <v-card v-else>
            <v-card-title class="text-h5 grey lighten-2">
            Archivos envíados
            </v-card-title>

            <v-card-text style="max-height: 300px">

            <v-list-item v-for="item in attached_items" :key="`attached_items_${item.id}`">
                <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                <v-btn icon elevation="2" @click="downloadAttachedFile(item.id)">
                    <v-icon v-text="icons.mdiDownload"></v-icon>
                </v-btn>
                </v-list-item-icon>
            </v-list-item>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="attached_files_dialog = false"
            >
                CERRAR
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
      </div>

      <template>
        <v-dialog
        v-model="showingFailedMessageModal"
        width="250"
        >
          <v-card
          class="text-center"
          >
            <v-card-text>
              <div><span>Canal: </span><strong>{{ failedMessage ? failedMessage.channel : '' }}</strong></div>
              <div class="mt-3"><span>Contacto: </span><strong>{{ failedMessage ? failedMessage.blacknumber : '' }}</strong></div>
              <div class="mt-3"><span>Teléfono: </span><strong>{{ failedMessage ? failedMessage.phone : '' }}</strong></div>
              <div class="mt-3"><span>Error: </span><strong>{{ failedMessage ? failedMessage.error : '' }}</strong></div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
            <v-btn color="primary " text @click="showingFailedMessageModal = false">
                CERRAR
            </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>


      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()">
          CERRAR
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Date & Time Info -->
    <div >
      <v-dialog
      v-model="showConfigModal"
      :width="configModalContent.label === 'config' ? '400' : '250'"
      >
          <v-card
          class="text-center"
          >
              <v-card-title class="primary--text text-h5 d-block">
              {{configModalContent.title}}
              </v-card-title>
              <v-divider></v-divider>
              <div class="mt-3">
                <div v-if="configModalContent.label === 'config' && configModalContent.content !== null">
                  <v-card-text v-for="(el, key) in configModalContent.content" :key="key" class="text-left">
                    <strong>{{ key + ': ' }}</strong>
                    <span>{{ el }}</span>
                  </v-card-text>
                </div>

                <div v-if="configModalContent.label === 'date' && configModalContent.content !== null">
                  <v-card-text v-for="(el, idx) in configModalContent.content" :key="idx">
                    {{ parseDays(el.day)}}: {{ el.start_hour.split(':', 2).join(':')}} a {{ el.end_hour.split(':', 2).join(':')}}
                    <!-- {{ `${el}`  }} -->
                  </v-card-text>
                </div>
              </div>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="() => showConfigModal = false">
                  CERRAR
              </v-btn>
              <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
      </v-dialog>
    </div>
  </v-dialog>
</template>

<script>
const debounce = require('debounce');
import { ref } from '@vue/composition-api';
import AlertPopUp  from '@/components/AlertPopUp.vue';
import {
    mdiClose,
    mdiHelpCircleOutline,
    mdiCalendarQuestion,
    mdiDownload,
    mdiPlus,
    mdiCellphoneWireless,
    mdiCellphoneMessageOff,
    mdiCheckAll,
    mdiMessageQuestionOutline,
    mdiMessageProcessingOutline,
    mdiEyePlusOutline
} from '@mdi/js'

export default {
  data:() => {
    return {
      ripener_details: {},
      ripener_messages: [],
      ripener_id: null,
      terciaryColor: 'rgb(255, 99, 132)',
      current_page: 1,
      searchQuery: '',
      stateFilterValue: -1,
      page_size: 10,
      attached_files_dialog: false,
      attached_items: [],
      long_msg: '',
      long_msg_modal: false,
      failedMessage: null,
      showingFailedMessageModal: false,
      showConfigModal: false,
      configModalContent: {
        title: 'un titulo',
        content: [0,1,2,3,4,5],
        label: '',
      },
    }
  },
  components: {
    AlertPopUp
  },
  methods: {
    async getRipener() {
      this.loading = true
      try {
        const ripener = await this.$api.getRipener(this.ripener_id)
        this.ripener_details = ripener[0]
      } catch(e) {
        console.log(e)
      } finally {
        this.loading = false
        this.setTimer()
      }
    },

    openModal(id) {
      this.ripener_id = id
      this.getRipener()
      this.showingRipenerModal = true
    },

    close() {
      this.current_page = 1
      this.ripener_id = null
      this.ripener_details = {}
      this.ripener_messages = []
      this.showingRipenerModal = false
    },

    async getRipenerMessages(id){
      this.loadingMsg = true
      try {
        const form = {
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
          status: this.stateFilterValue,
        }

        const item_messages = await this.$api.getRipenerMessageList(id ? id : this.ripener_id,form)
        this.ripener_messages = item_messages.results
        this.totalMessageListTable = item_messages.count
      } catch(e) {
          console.log(e)
      } finally {
          this.loadingMsg = false
      }
    },

    handleSearch: debounce(function (e) {
      this.current_page = 1
      this.getRipenerMessages()
    }, 400),

    setFilterStatus() {
      this.current_page = 1
      this.getRipenerMessages()
    },

    changingRipenerMsgPage(e) {
      this.current_page = e
      this.getRipenerMessages()
    },

    changingRipenerMsgItemsPerPage(e) {
      this.page_size = e
      this.getRipenerMessages()
    },

    async checkStatusAck(item) {
      this.loading = true
      // this.loadIcon = true
      try {
        const response = await this.$api.checkStatusAck(item)
        this.campaign_messages.forEach(element => element.id === item ? element.ack_status = response : false)
      } catch (error) {
        console.log(error)
      } finally { this.loading = false; /* this.loadIcon = false; */}
    },

    translateAck(value) {
      const translation = {
        send: 'Enviado',
        received: 'Recibido',
        read: 'Leído',
      }
      return translation[value] ?? 'Leído'
    },

    translateStatus(value) {
      const translation = {
        0: 'PENDIENTE',
        1: 'ENVIADO',
        2: 'FALLIDO',
      }
      return translation[value] ?? 'error'
    },

    openAttachedFiles(files) {
      this.attached_items = files
      this.attached_files_dialog = true
    },

    async downloadAttachedFile(file_id) {
      const url = await this.$api.downloadRipenerFile(file_id)
      this.createLinkClicked(url)
    },

    createLinkClicked(url) {
      const a = document.createElement("a");
      a.href = url
      a.target = '_blank'
      // console.log(url)
      a.click();
    },

    showCompleteMsg(message) {
      this.long_msg = message
      this.long_msg_modal = true
    },

    setTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        this.getRipener()
        this.getRipenerMessages()
      }, 1000 * 60)
    },

    async openFailedMessageModal(id) {
      try {
        const response = await this.$api.getRipenerFailedMessages(id)
        this.failedMessage = response[0]
        this.showingFailedMessageModal = true
        console.log(response)
      } catch(e) {
        console.log(e)
      }
    },
    handleConfigModal(label) {

      this.configModalContent.label = label
      if(label === 'config') {
        this.configModalContent.title = 'Configuración del madurador'
        this.configModalContent.content = {
          "Fecha de inicio": this.ripener_details.start_date,
          "Fecha de finalización": this.ripener_details.ending_date,
          "Espera mínima para la conversación": this.ripener_details.minim_wait + ' (segundos)',
          "Espera máxima para la conversación": this.ripener_details.maxim_wait + ' (segundos)',
          "Espera mínima para los mensajes iniciales": this.ripener_details.minim_initial_wait + ' (segundos)',
          "Espera máxima para los mensajes iniciales": this.ripener_details.maxim_initial_wait + ' (segundos)',
          "Tiempo de maduración": this.ripener_details.work_datetime ? (this.ripener_details.work_datetime) / 60 + ' (Minutos)' : 'Sin especificar',
          "Tiempo descanso Min.": this.ripener_details.break_time_min ? (this.ripener_details.break_time_min) / 60 + ' (Minutos)' : 'Sin especificar',
          "Tiempo descanso Max.": this.ripener_details.break_time_max ? (this.ripener_details.break_time_max) / 60 + ' (Minutos)' : 'Sin especificar',
          "Mensajes por bloque": this.ripener_details.messages_to_break ?? 'Sin especificar',
        }
      } else {
        this.configModalContent.title = 'Días y horarios'
        this.configModalContent.content = this.ripener_details.days
      }
      this.showConfigModal = true
    },
    parseDays(day){
      const weekDays = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
      return weekDays[day];
    },
  },
  watch: {
    ripener_id(newValue) {
      if(newValue) {
        this.getRipenerMessages(this.ripener_id)
      }
    },
    showConfigModal(newValue) {
      if(newValue === false) {
        this.configModalContent.label = '',
        this.configModalContent.title = '',
        this.configModalContent.content = null
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  computed: {
    statusParser() {
      const parser = {
        0: 'Procesando',
        1: 'Enviando',
        2: 'Pausado',
        3: 'Detenido',
        4: 'Finalizado',
        5: 'En creación',
        6: 'Fallido'
      }
      return parser
    }
  },

  setup() {
    const showingRipenerModal = ref(false)
    const loading = ref(false)
    const loadingMsg = ref(false)

    const totalMessageListTable = ref(0)

    const tableColumns = [
      { text: 'MENSAJE INICIADO', value: 'validation_ok_whatscupon', align: 'center', sortable: false },
      { text: 'USUARIO', value: 'user', align: 'center', sortable: false },
      { text: 'CANAL', value: 'channel', align: 'center', sortable: false },
      { text: 'TELÉFONO', value: 'phone', align: 'center', sortable: false },
      { text: 'MENSAJE', value: 'message', align: 'center', sortable: false },
      { text: 'FECHA', value: 'date', align: 'center', sortable: false },
      { text: 'HORA', value: 'time', align: 'center', sortable: false },
      { text: 'ACTIVIDAD', value: 'work_status', align: 'center', sortable: false },
      { text: 'ESTADO', value: 'status', align: 'center', sortable: false },
      { text: 'ARCHIVOS', value: 'files', align: 'center', sortable: false },
    ]

    const resolveUserStatusVariant = status => {
      if (status === 2) return 'error'
      if (status === 1) return 'success'
      if (status === 0) return 'primary'

      return 'primary'
    }

    return {
      showingRipenerModal,
      totalMessageListTable,
      resolveUserStatusVariant,
      tableColumns,
      loadingMsg,
      loading,
      icons: {
        mdiClose,
        mdiHelpCircleOutline,
        mdiCalendarQuestion,
        mdiDownload,
        mdiPlus,
        mdiCellphoneWireless,
        mdiCellphoneMessageOff,
        mdiCheckAll,
        mdiMessageQuestionOutline,
        mdiMessageProcessingOutline,
        mdiEyePlusOutline
      }
    }
  }
}
</script>

<style scoped>
.tool-bar {
    box-shadow: 0 0px 1px 0px rgba(94, 86, 105, 0.56) !important;
}
</style>
