<template>
  <v-dialog
    v-model="showingRipenerFormModal"
    width="700"
    scrollable
    persistent
    minHeight="1200"
  >
    <v-form ref="ripenerForm" @submit.prevent="createRipener">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ copyRipenerItem ? "Copiar Madurador" : "Crear Madurador" }}
        </v-card-title>
        <v-divider></v-divider>
        <!-- <v-card-subtitle class="pb-0 text-subtitle-1">El primer mensaje debe ser contestado con <strong class="primary--text">“ok whatscupon”</strong> para permitir el inicio del madurador.</v-card-subtitle> -->

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                ref="name"
                v-model="form_name"
                :rules="[(v) => !!v || 'Nombre requerido']"
                outlined
                dense
                autocomplete="off"
                hide-details
                label="Nombre del madurador"
              ></v-text-field>
            </v-col>

            <v-col v-if="!copyRipenerItem" cols="12" md="12" class="py-0">
              <v-row>
                <v-col cols="6" md="6" class="pb-0">
                  <v-select
                    v-model="form_specific_response"
                    :items="[
                      { name: 'Todas', value: false },
                      { name: 'Especificar', value: true },
                    ]"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    hide-details
                    label="Respuesta inicial"
                  ></v-select>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-if="form_specific_response"
                    v-model="form_expected_response"
                    :rules="[(v) => !!v || 'Campo requerido']"
                    outlined
                    type="text"
                    dense
                    label="Mensaje"
                  ></v-text-field>
                  <div v-else>
                    <span class="text-caption">
                      Con esta opción, cualquier respuesta del cliente puede activar el
                      madurador.</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col v-if="!copyRipenerItem" cols="6" md="6" class="py-0 mt-0">
              <div class="d-flex align-center" style="height: auto">
                <v-checkbox
                  v-model="form_wait_answer"
                  class="ps-3 mt-0 mb-2"
                  hide-details
                >
                </v-checkbox>
                <label class="pt-0 custom-labels"> Esperar respuesta para iniciar</label>
              </div>
            </v-col>

            <v-col cols="12" md="12" class="py-0 mt-2">
              <h3 class="text-l font-weight-medium d-flex align-center">
                <v-icon class="mr-2">{{ icons.mdiAccountBox }}</v-icon>
                Seleccionar contactos
                <v-tooltip right v-if="radio_option === 'blacknumber_option'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-0" color="primary" dense v-bind="attrs" v-on="on">
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>Solo se muestran los contactos de la blacklist.</span>
                </v-tooltip>
              </h3>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <v-radio-group
                ref="radio_group"
                class="mt-2"
                v-model="radio_option"
                :rules="[(v) => !!v || 'Campo requerido']"
                @change="resetRadioOptions()"
                row
              >
                <v-radio
                  label="Seleccionar Contacto"
                  value="blacknumber_option"
                ></v-radio>
                <v-radio label="Digitar los números" value="numbers_option"></v-radio>
              </v-radio-group>

              <!--Numbers option-->
              <v-col
                cols="12"
                md="12"
                class="pt-0"
                v-if="radio_option === 'numbers_option'"
              >
                <v-list>
                  <v-list-item v-for="(input, k) in form_number" :key="k">
                    <v-row class="mt-2">
                      <v-col cols="3" md="3">
                        <v-select
                          id="selectorflags"
                          name="selectorflags"
                          v-model="input.selectorFlags"
                          :items="itemsPrefix"
                          label="Cod. Area"
                          :rules="[(v) => !!v || 'Caracteristica requerida']"
                          required
                          hide-details="auto"
                          outlined
                          dense
                          @change="changePrefix($event, k)"
                        ></v-select>
                      </v-col>
                      <v-col cols="9" md="9">
                        <v-text-field
                          v-model="input.number"
                          type="number"
                          outlined
                          :rules="[(v) => !!v || 'El número es necesario']"
                          dense
                          placeholder="Número de Whatsapp"
                          hide-details
                          label="Whatsapp"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-textarea
                          :prepend-inner-icon="icons.mdiMessageBulleted"
                          ref="description"
                          v-model="input.description"
                          :rules="[(v) => !!v || 'Descripción requerida']"
                          counter="500"
                          maxlength="500"
                          placeholder="Descripción"
                          id="textarea-input-blacklist"
                          outlined
                          dense
                          hint=""
                          hide-details
                          label="Descripción"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          ref="name"
                          v-model="input.name"
                          :rules="[(v) => !!v || 'Nombre requerido']"
                          outlined
                          dense
                          autocomplete="off"
                          hide-details
                          label="Nombre del contacto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" md="4">
                        <div class="d-flex " style="height: auto">
                          <v-checkbox
                            v-model="input.add_blacklist"
                            class="mt-0"
                            hide-details
                          >
                          </v-checkbox>
                          <label class=" custom-labels"> Agregar contacto a la blacklist </label>
                        </div>
                      </v-col>
                      <v-col cols="2" md="2">
                        <v-btn
                          icon
                          color="error"
                          @click="removeNumberInput(k)"
                          v-if="radio_option === 'numbers_option'"
                        >
                          <v-icon>{{ icons.mdiMinusCircleOutline }}</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-btn
                icon
                color="primary"
                @click="addNumberInput()"
                v-if="radio_option === 'numbers_option'"
              >
                <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon>
              </v-btn>
              <span
                class="error--text error-msg"
                v-if="form_number_msg && radio_option === 'numbers_option'"
                >{{ form_number_msg }}</span
              >
              <!--Blacknumber option-->
              <v-col
                cols="12"
                md="12"
                class="pt-0 pb-0"
                v-if="radio_option === 'blacknumber_option'"
              >
                <v-combobox
                  v-model="form_blacknumbers"
                  :items="blacknumbersSelectData"
                  :search-input.sync="searchContactQuery"
                  :rules="[(v) => !!v || 'Campo requerido']"
                  label="Seleccione uno o varios contactos"
                  outlined
                  no-filter
                  dense
                  multiple
                  chips
                  deletable-chips
                  item-text="name"
                  :item-value="(item) => item"
                >
                  <template #item="data">
                    <v-list-item-content @click="searchContactQuery = ''">
                      <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        No se encontraron <strong>contactos</strong>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-combobox>
                <span
                  class="error--text error-msg"
                  v-if="form_blacknumber_msg && radio_option === 'blacknumber_option'"
                  >{{ form_blacknumber_msg }}</span
                >
              </v-col>
            </v-col>
          </v-row>

          <v-row v-if="!copyRipenerItem" class="mb-3">
            <v-col cols="12" md="12" class="pt-0 pb-0">
              <div class="d-flex align-center" style="height: auto">
                <v-checkbox
                  v-model="form_all_channels"
                  class="ps-3 mt-4 mb-2"
                  hide-details
                >
                </v-checkbox>
                <label class="pt-3 custom-labels"> Seleccionar todos los canales</label>
              </div>

              <v-combobox
                v-model="form_channels"
                :items="channelsData"
                item-text="name"
                item-value="id"
                chips
                deletable-chips
                :search-input.sync="searchChannelQuery"
                no-filter
                :disabled="form_all_channels"
                :rules="!form_all_channels ? [(v) => !!v || 'Campo requerido'] : []"
                label="Seleccione uno o varios canales"
                outlined
                dense
                multiple
              >
                <template #item="data">
                  <v-list-item-content @click="searchChannelQuery = ''">
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      No se encontraron <strong>canales</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-combobox>

            </v-col>

            <v-col cols="12" md="12" class="pt-0 pb-0">
              <div class="d-flex align-center" style="height: auto">
                <v-checkbox
                  v-model="form_all_ripenerTexts"
                  class="ps-3 mt-4 mb-2"
                  hide-details
                ></v-checkbox>
                <label class="pt-3 custom-labels">
                  Seleccionar todos los modelos de mensaje
                </label>
              </div>

              <v-combobox
                v-model="form_ripenerTexts"
                :items="ripenerTextsData"
                item-text="name"
                item-value="id"
                chips
                no-filter
                deletable-chips
                :search-input.sync="searchTextsQuery"
                :disabled="form_all_ripenerTexts"
                :rules="!form_all_ripenerTexts ? [(v) => !!v || 'Campo requerido'] : []"
                label="Seleccione un o varios modelos de mensaje"
                outlined
                dense
                multiple
              >
                <template #item="data">
                  <v-list-item-content @click="searchTextsQuery = ''">
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      No se encontraron <strong>modelos</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                ref="minim_wait"
                v-model="form_minim_wait"
                :rules="[(v) => !!v || 'Campo requerido']"
                outlined
                dense
                type="number"
                autocomplete="off"
                hide-details
                min="0"
                label="Espera mínima para la conversación (segundos)"
                :error="errorMinMaxWait"
                @change="errorMinMaxWait = false"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                ref="maxim_wait"
                v-model="form_maxim_wait"
                :rules="[(v) => !!v || 'Campo requerido']"
                outlined
                type="number"
                min="0"
                dense
                autocomplete="off"
                hide-details
                label="Espera máxima para la conversación (segundos)"
                @change="errorMinMaxWait = false"
                :error="errorMinMaxWait"
              ></v-text-field>
            </v-col>
            
            <v-col cols="6" md="6">
              <v-text-field
                ref="minim_initial_wait"
                v-model="form_minim_initial_wait"
                :rules="[(v) => !!v || 'Campo requerido']"
                outlined
                dense
                type="number"
                autocomplete="off"
                hide-details
                min="0"
                label="Espera mínima para los mensajes iniciales (segundos)"
                :error="errorMinMaxWait"
                @change="errorMinMaxWait = false"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                ref="maxim_initial_wait"
                v-model="form_maxim_initial_wait"
                :rules="[(v) => !!v || 'Campo requerido']"
                outlined
                type="number"
                min="0"
                dense
                autocomplete="off"
                hide-details
                label="Espera máxima para los mensajes iniciales (segundos)"
                @change="errorMinMaxWait = false"
                :error="errorMinMaxWait"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!--Date picker-->
            <v-col cols="6" md="6" class="py-0">
              <h3 class="text-l font-weight-medium d-flex align-center">
                <v-icon class="mr-2">{{ icons.mdiCalendarMonth }}</v-icon>
                Fecha de inicio
              </h3>
            </v-col>
            <v-col cols="6" md="6" class="py-0">
              <h3 class="text-l font-weight-medium d-flex align-center">
                <v-icon class="mr-2">{{ icons.mdiCalendarMonth }}</v-icon>
                Fecha de finalización
              </h3>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Fecha de inicio"
                type="date"
                v-model="form_start_date"
                :rules="[(v) => !!v || 'Campo requerido']"
                :min="minDate"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6" class="">
              <v-radio-group
                ref="radio_group_date"
                class="mt-0 pt-0"
                v-model="radio_option_date"
                :rules="[(v) => !!v || 'Campo requerido']"
                @change="resetDateRadioOptions()"
                row
              >
                <v-radio
                  label="Fecha de finalización"
                  value="end_date_option"
                ></v-radio>
                <v-radio 
                label="Cantidad de días a madurar" 
                value="days_option"
                ></v-radio>
              </v-radio-group>
              <v-text-field
                label="Fecha de finalización"
                type="date"
                v-if="radio_option_date === 'end_date_option'"
                v-model="form_end_date"
                :rules="[(v) => !!v || 'Campo requerido']"
                :min="minDate"
                outlined
                dense
                required
              ></v-text-field>

              <div class="d-flex">
                <v-text-field
                  v-if="radio_option_date === 'days_option'"
                  v-model="form_end_days"
                  :rules="[(v) => !!v || 'Campo requerido']"
                  outlined
                  validate-on-blur
                  style="width: 70%;"
                  type="number"
                  dense
                  autocomplete="off"
                  label="Días"
                ></v-text-field>
                <v-tooltip right v-if="radio_option_date === 'days_option'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-0 mt-0 mb-7" color="primary" dense v-bind="attrs" v-on="on">
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>Cantidad de días a madurar desde la fecha de inicio.</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="!copyRipenerItem">
            <v-col cols="12" md="12" class="py-0">
              <h3 class="text-l font-weight-medium d-flex align-center">
                Tiempo de maduración
              </h3>
            </v-col>
            <v-col cols="6" md="6">
              <v-select
                ref="worktime_select"
                class="mb-4"
                :items="[
                  { name: 'Sin definir', value: -1 },
                  { name: 'Días', value: 0 },
                  { name: 'Horas', value: 1 },
                  { name: 'Minutos', value: 2 },
                ]"
                hide-details="true"
                v-model="worktime_select"
                label="Tiempo"
                item-text="name"
                item-value="value"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                v-if="worktime_select >= 0"
                ref="worktime"
                v-model="form_worktime"
                outlined
                type="number"
                min="1"
                max="59"
                dense
                autocomplete="off"
                hide-details
                label="Maduración"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12" class="py-0">
              <h3 class="text-l font-weight-medium d-flex align-center">
                Tiempo de descanso
              </h3>
            </v-col>
            <v-col cols="4" md="4">
              <v-select
                ref="breaktime_select"
                class="mb-4"
                :items="[
                  { name: 'Sin definir', value: -1 },
                  { name: 'Días', value: 0 },
                  { name: 'Horas', value: 1 },
                  { name: 'Minutos', value: 2 },
                ]"
                hide-details="true"
                v-model="breaktime_select"
                label="Tiempo"
                item-text="name"
                item-value="value"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="4" md="4" v-if="breaktime_select >= 0">
              <v-text-field
                ref="breaktime_min"
                v-model="form_breaktime_min"
                :rules="[validateMinMax]"
                outlined
                validate-on-blur
                type="number"
                dense
                autocomplete="off"
                label="Descanso mínimo"
              ></v-text-field>
            </v-col>
            <v-col cols="4" md="4">
              <v-text-field
                v-if="breaktime_select >= 0"
                ref="breaktime_max"
                v-model="form_breaktime_max"
                :rules="[validateMinMax]"
                outlined
                validate-on-blur
                type="number"
                dense
                autocomplete="off"
                label="Descanso máximo"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="!copyRipenerItem">
            <v-col cols="12" md="12" class="py-0">
              <h3 class="text-l font-weight-medium d-flex align-center">
                Cantidad de mensajes a enviar por bloque de trabajo
              </h3>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                ref="messages_to_break"
                v-model="form_messages_to_break"
                outlined
                :rules="[validateMessagesToBreak]"
                validate-on-blur
                type="number"
                dense
                autocomplete="off"
                label="Mensajes a enviar"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="!copyRipenerItem">
            <!--Send times-->
            <v-col cols="12" md="12" class="py-0">
              <h3 class="text-l font-weight-medium d-flex align-center">
                <v-icon class="mr-2">{{ icons.mdiClockTimeSevenOutline }}</v-icon>
                Días y horario de envio de mensajes
              </h3>
            </v-col>
            <v-col cols="12" md="12">
              <v-list flat>
                <v-list-item-group multiple>
                  <v-list-item
                    v-for="(item, i) in itemsDays"
                    :key="i"
                    :value="item"
                    class="d-flex"
                  >
                    <v-list-item-action>
                      <v-checkbox v-model="item.active" color="primary"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.day }}</v-list-item-title>
                    </v-list-item-content>
                    <div class="d-flex mt-4" style="width: 50%">
                      <v-menu
                        ref="time_start_refs"
                        v-model="item.timepicker_menu_start"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="item.send_time_start"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        :disabled="!item.active"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.send_time_start"
                            label="Desde"
                            :prepend-icon="icons.mdiClockTimeFourOutline"
                            :rules="[(v) => !!v || 'Campo requerido']"
                            :disabled="!item.active"
                            outlined
                            dense
                            required
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="item.timepicker_menu_start"
                          v-model="item.send_time_start"
                          full-width
                          format="24hr"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            outlined
                            color="primary"
                            @click="$refs.time_start_refs[i].save(item.send_time_start)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-menu>
                      <v-menu
                        ref="time_end_refs"
                        v-model="item.timepicker_menu_end"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="item.send_time_end"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        :disabled="!item.active"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.send_time_end"
                            label="Hasta"
                            :prepend-icon="icons.mdiClockTimeFourOutline"
                            :rules="[(v) => !!v || 'Campo requerido']"
                            :disabled="!item.active"
                            outlined
                            dense
                            required
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="item.timepicker_menu_end"
                          v-model="item.send_time_end"
                          full-width
                          format="24hr"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.time_end_refs[i].save(item.send_time_end)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-menu>
                    </div>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <span class="error--text error-msg" v-if="days_checkbox_msg">{{
                days_checkbox_msg
              }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="closeModal()"> CANCELAR </v-btn>

          <v-btn color="primary" type="submit">
            {{ copyRipenerItem ? "COPIAR MADURADOR" : "CREAR MADURADOR" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <alert-pop-up ref="alert_pop_up" />
  </v-dialog>
</template>

<script>
const debounce = require("debounce");
import { ref } from "@vue/composition-api";
import AlertPopUp from "@/components/AlertPopUp.vue";
import {
  mdiAccountBox,
  mdiInformationOutline,
  mdiMinusCircleOutline,
  mdiPlusCircleOutline,
  mdiMessageBulleted,
  mdiCalendarMonth,
} from "@mdi/js";

export default {
  data: () => {
    return {
      searchContactQuery: "",
      searchTextsQuery: "",
      searchChannelQuery: "",
      form_name: "",
      form_specific_response: false,
      form_expected_response: "",
      copyRipenerItem: null,
      form_number: [
        {
          name: null,
          description: null,
          prefix: "+549",
          number: null,
          selectorFlags: "🇦🇷 (+549)",
          add_blacklist: true
        },
      ],
      form_blacknumbers: [],
      radio_option: null,
      radio_option_date: 'end_date_option',
      blacknumbersSelectData: [],
      form_blacknumber_msg: null,
      form_number_msg: null,
      form_all_channels: false,
      form_channels: null,
      form_all_ripenerTexts: false,
      form_wait_answer: false,
      form_ripenerTexts: null,
      form_start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      form_end_date: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10),
      form_end_days: 0,
      worktime_select: -1,
      form_worktime: null,
      breaktime_select: -1,
      form_breaktime_min: null,
      form_breaktime_max: null,
      send_time_start: null,
      send_time_end: null,
      form_messages_to_break: null,
      minDate: null,
      checkDays: false,
      days_checkbox_msg: null,
      time_start_refs: [],
      time_end_refs: [],
      itemsDays: [],
      errorMinMaxWait: false,
      form_maxim_wait: null,
      form_minim_wait: null,
      form_maxim_initial_wait: null,
      form_minim_initial_wait: null,
      channelsData: [],
      ripenerTextsData: [],
    };
  },
  components: {
    AlertPopUp,
  },
  methods: {
    openModal(item_to_copy) {
      this.setBlacknumbersData();
      this.getChannels();
      this.getRipenerTexts();
      this.setMinDate();
      this.form_start_date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.form_end_date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10);
      this.itemsDays = this.defaultItemsDays();
      this.worktime_select = -1;
      this.breaktime_select = -1;
      this.form_specific_response = false;
      item_to_copy
        ? this.setItemToCopy(item_to_copy)
        : (this.showingRipenerFormModal = true);
    },

    setItemToCopy(item_to_copy) {
      const item = item_to_copy;
      if (item) {
        this.copyRipenerItem = item;
      }

      this.showingRipenerFormModal = true;
    },

    closeModal() {
      this.resetForm();
      this.resetRadioOptions();
      this.resetDateRadioOptions()
      this.radio_option = null;
      this.copyRipenerItem = null;
      this.form_maxim_wait = null;
      this.form_minim_wait = null;
      this.form_maxim_initial_wait = null;
      this.form_minim_initial_wait = null;
      this.form_worktime = null;
      this.form_breaktime_min = null;
      this.form_breaktime_max = null;
      this.form_messages_to_break = null;
      this.showingRipenerFormModal = false;
    },

    resetForm() {
      this.$refs.ripenerForm.reset();
      this.form_all_channels = false;
      this.form_all_ripenerTexts = false;
      this.form_wait_answer = false;
      this.form_channels = null;
      this.form_ripenerTexts = null;
      this.checkDays = false;
      this.worktime_select = -1;
      this.breaktime_select = 1;
    },

    setMinDate() {
      const date = new Date();
      this.minDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];
    },

    async getChannels() {
      const form = {
      company_id: 'all', //get all channels, for all companies
      page: 1,
      page_size: 10,
      search: this.searchChannelQuery,
    }
      const channels = await this.$api.getChannels(form);
      this.channelsData = channels.results;
    },

    handleChannelSearch: debounce(function (e) {
        this.getChannels();
      }, 300),

    async getRipenerTexts() {
      const query = {
        only_active: true,
        page: 1,
        page_size: 10,
        search: this.searchTextsQuery,
      };

      try {
        const ripenerTexts = await this.$api.getRipenerTextList(query);
        this.ripenerTextsData = ripenerTexts.results;
        console.log(this.ripenerTextsData, this.searchTextsQuery)
        console.log(ripenerTexts.results)
      } catch (error) {
        console.log(error);
      }
    },

    handleTextsSearch: debounce(function (e) {
      this.getRipenerTexts();
    }, 300),

    resetRadioOptions() {
      this.setBlacknumbersData();
      this.form_number = [
        {
          name: null,
          prefix: "+549",
          description: null,
          number: null,
          selectorFlags: "🇦🇷 (+549)",
          add_blacklist: true
        },
      ];
      this.blacknumbersSelectData = [];
    },

    resetDateRadioOptions() {
      this.form_end_days = 0
      this.form_end_date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10)
    },

    changePrefix(e, i) {
      const matches = e.match(/\d+/g);

      if (matches) this.form_number[i].prefix = `+${matches[0]}`;
    },

    addNumberInput() {
      this.form_number.push({
        name: null,
        description: null,
        prefix: "+549",
        number: null,
        selectorFlags: "🇦🇷 (+549)",
        add_blacklist: true
      });
    },

    removeNumberInput(index) {
      this.form_number.splice(index, 1);
    },

    async setBlacknumbersData() {
      if (this.getSelectedCompany) {
        const query = {
          company_list: this.getSelectedCompany,
          page: 1,
          page_size: 10,
          search: this.searchContactQuery,
        };

        try {
          const response = await this.$api.getBlacklist(query);
          this.blacknumbersSelectData = response.results;
          // this.form_blacknumbers = []
        } catch (error) {
          console.log(error);
        }
      }
    },

    handleContactSearch: debounce(function (e) {
      this.setBlacknumbersData();
    }, 300),

    defaultItemsDays() {
      return [
        {
          day: "Lunes",
          number: 0,
          send_time_start: "08:00",
          send_time_end: "21:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: false,
        },
        {
          day: "Martes",
          number: 1,
          send_time_start: "08:00",
          send_time_end: "21:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: false,
        },
        {
          day: "Miércoles",
          number: 2,
          send_time_start: "08:00",
          send_time_end: "21:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: false,
        },
        {
          day: "Jueves",
          number: 3,
          send_time_start: "08:00",
          send_time_end: "21:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: false,
        },
        {
          day: "Viernes",
          number: 4,
          send_time_start: "08:00",
          send_time_end: "21:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: false,
        },
        {
          day: "Sábado",
          number: 5,
          send_time_start: "08:00",
          send_time_end: "21:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: false,
        },
        {
          day: "Domingo",
          number: 6,
          send_time_start: "08:00",
          send_time_end: "21:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: false,
        },
      ];
    },

    async createRipener() {
      this.form_number_msg = null;
      this.days_checkbox_msg = null;
      this.form_blacknumber_msg = null;

      if(!this.copyRipenerItem) {
        this.checkDays = this.itemsDays.some((item) => item.active);
  
        if (!this.checkDays) {
          this.days_checkbox_msg = "Es requerido seleccionar un día como mínimo.";
        }

      }

      if (!this.form_blacknumbers.length && this.radio_option === "blacknumber_option") {
        this.form_blacknumber_msg = "Es requerido seleccionar un contacto como mínimo.";
      }

      if (!this.form_number.length) {
        this.form_number_msg = "Es requerido ingresar un contacto como mínimo.";
      }

      if (this.radio_option === "numbers_option") {
        this.form_number.forEach((el) => {
          if (!el.prefix || !el.number || !el.name) {
            this.form_number_msg = "Es requerido ingresar un contacto como mínimo.";
          }
        });
      }

      if (
        this.$refs.ripenerForm.validate() &&
        !this.days_checkbox_msg &&
        !this.form_blacknumber_msg &&
        !this.form_number_msg
      ) {
        //send only active checkboxes
        const itemsDateTime = this.itemsDays
          .filter((item) => item.active === true)
          .map((item) => ({
            number: item.number,
            send_time_start: item.send_time_start,
            send_time_end: item.send_time_end,
          }));

        let form_blacknumbers_ids = [];
        this.form_blacknumbers.forEach((el) => {
          if(el.id) {
            form_blacknumbers_ids.push(el.id)
          }
        })
        if (this.radio_option === "blacknumber_option") {
          form_blacknumbers_ids = form_blacknumbers_ids
        }

        const form = {
          name: this.form_name,
          numbers:
            this.radio_option === "numbers_option"
              ? JSON.stringify(this.form_number)
              : [],
          all_channels: this.form_all_channels ? "True" : "False",
          channels_id: this.form_channels
            ? JSON.stringify(this.form_channels.map((el) => el.id))
            : "[]",
          all_ripenertext: this.form_all_ripenerTexts ? "True" : "False",
          wait_answer: this.form_wait_answer ? "True" : "False",
          ripenertexts_id: this.form_ripenerTexts
            ? this.form_ripenerTexts.map((el) => el.id)
            : "[]",
          start_date: this.form_start_date,
          date_time: JSON.parse(JSON.stringify(itemsDateTime)),
          company_id: this.getSelectedCompany,
          list_id_blacknumber: form_blacknumbers_ids,
          minim_wait: this.form_minim_wait,
          maxim_wait: this.form_maxim_wait,
          minim_initial_wait: this.form_minim_initial_wait,
          maxim_initial_wait: this.form_maxim_initial_wait,
          break_unit: this.breaktime_select ?? 1,
          specific_response: this.form_specific_response ?? false,
        };
        //if this fields are empty dont send it
        if (this.worktime_select && this.worktime_select >= 0)
          form.work_unit = this.worktime_select;
        if (this.form_worktime && this.form_worktime >= 0)
          form.work_time = this.form_worktime;
        if (this.form_breaktime_min && this.form_breaktime_min >= 0)
          form.break_time_min = this.form_breaktime_min;
        if (this.form_breaktime_max && this.form_breaktime_max >= 0)
          form.break_time_max = this.form_breaktime_max;
        if (this.form_messages_to_break && this.form_messages_to_break.trim() !== "")
          form.messages_to_break = this.form_messages_to_break;
        if (this.form_expected_response && this.form_expected_response.trim() !== "")
          form.expected_response = this.form_expected_response;
        console.log(form);
        let copyForm = {
          name: form.name,
          numbers: form.numbers,
          start_date: form.start_date,
          list_id_blacknumber: form.list_id_blacknumber,
          company_id: form.company_id
        }
        if(this.radio_option_date === 'end_date_option') {
          form.ending_date = this.form_end_date
          copyForm.ending_date = this.form_end_date
        }
        if(this.radio_option_date === 'days_option') {
          form.number_days = this.form_end_days
          copyForm.number_days = this.form_end_days
        }
        try {
          const response = this.copyRipenerItem 
                          ? await this.$api.copyRipener(copyForm, this.copyRipenerItem.id)
                          : await this.$api.createRipener(form);
          this.$emit("reload-ripener-list");
          this.closeModal();
        } catch (e) {
          console.log(e);
          if (e?.response?.status === 403) {
            this.$refs.alert_pop_up.showPopUp({
              message: e?.response?.data?.message,
              type: "error",
            });
          }
        }
      }
    },
    validateMinMax() {
      const min = this.form_breaktime_min;
      const max = this.form_breaktime_max;

      if (min !== null && min !== "" && max !== null && max !== "") {
        return min !== max || "El mínimo y el máximo no pueden ser iguales";
      }
      return true;
    },
    validateMessagesToBreak(value) {
      if (this.breaktime_select >= 0 && !value) {
        return "Requerido si establece tiempo de descanso";
      }
      return true;
    },
  },
  watch: {
    searchContactQuery(val) {
      this.handleContactSearch();
    },
    searchTextsQuery(val) {
      this.handleTextsSearch();
    },
    searchChannelQuery(val) {
      this.handleChannelSearch();
    },
  },
  computed: {
    getSelectedCompany() {
      return this.$store.getters["session/getSelectedCompany"]();
    },
  },
  setup() {
    const showingRipenerFormModal = ref(false);
    const itemsPrefix = [
      "🇦🇷 (+549)",
      "🇧🇷 (+55)",
      "🇧🇴 (+591)",
      "🇨🇱 (+56)",
      "🇨🇴 (+57)",
      "🇪🇨 (+593)",
      "🇲🇽 (+52)",
      "🇵🇪 (+51) ",
      "🇵🇾 (+595)",
      "🇺🇾 (+598)",
      "🇻🇪 (+58)",
    ];

    return {
      showingRipenerFormModal,
      itemsPrefix,
      icons: {
        mdiAccountBox,
        mdiInformationOutline,
        mdiMinusCircleOutline,
        mdiPlusCircleOutline,
        mdiMessageBulleted,
        mdiCalendarMonth,
      },
    };
  },
};
</script>
<style scoped>
.custom-labels {
  font-size: 16px;
  min-height: 8px;
}
</style>
