
<template>
  <v-dialog v-model="showingCompanyModal" width="750" scrollable persistent minHeight="1200">
    <v-form ref="companyForm" @submit.prevent="createCompany">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ editFormID? 'Editar empresa' : 'Crear empresa' }}
        </v-card-title>

        <v-card-text style="max-height: 500px">


            <v-row class="mt-2">

              <v-col cols="12" md="12">
                <v-text-field
                  ref="name"
                  v-model="company_form.name"
                  :rules="[v => !!v || 'Nombre requerido']"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details
                  label="Nombre de la instancia"
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="0">
                <v-select
                  name="companySelector"
                  v-model="company_form.open"
                  :items="hours_selection"
                  label="Horario de apertura (00 a 23)"
                  :rules="[v => !!v || 'Caracteristica requerida']"
                  required
                  hide-details="auto"
                  outlined
                  dense
                ></v-select>
              </v-col>

              <v-col cols="6" md="6">
                <v-select
                  name="companySelector"
                  v-model="company_form.close"
                  :items="hours_selection"
                  label="Horario de cierre (00 a 23)"
                  :rules="[v => !!v || 'Caracteristica requerida']"
                  required
                  hide-details="auto"
                  outlined
                  dense
                ></v-select>
              </v-col>


              <!-- <v-col cols="12" md="12" class="py-0">
                <h3 class="text-l font-weight-medium d-flex align-center">
                  Tiempos de Espera
                </h3>
              </v-col> -->
              <v-col cols="6" md="0">
                <v-text-field
                  ref="minim_wait"
                  v-model="company_form.minim_wait"
                  :rules="[v => !!v || 'Campo requerido']"
                  outlined
                  dense
                  type="number"
                  autocomplete="off"
                  hide-details
                  min="0"
                  label="Espera mínima (segundos)"
                  :error="errorMinMaxWait"
                  @change="errorMinMaxWait = false"
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="6">
                <v-text-field
                  ref="maxim_wait"
                  v-model="company_form.maxim_wait"
                  :rules="[v => !!v || 'Campo requerido']"
                  outlined
                  type="number"
                  min="0"
                  dense
                  autocomplete="off"
                  hide-details
                  label="Espera máxima (segundos)"
                  @change="errorMinMaxWait = false"
                  :error="errorMinMaxWait"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" v-if="!editFormID">
                <v-text-field
                  ref="credits"
                  v-model="company_form.credits"
                  :rules="[v => !!v || 'Campo requerido']"
                  outlined
                  dense
                  autocomplete="off"
                  type="number"
                  hide-details
                  label="Créditos"
                ></v-text-field>
              </v-col>


              <v-col cols="12" md="12">
                <v-checkbox
                  ref="hidden_message"
                  v-model="company_form.hidden_message"
                  class="mt-0"
                  hide-details
                  label="Hidden Message"
                ></v-checkbox>
              </v-col>


              <v-col cols="12" md="12" v-if="company_form.hidden_message">
                <v-text-field
                  ref="hidden_message_body"
                  v-model="company_form.hidden_message_body"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details
                  label="Hidden Message Body"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field
                  ref="limit_day"
                  v-model="company_form.limit_day"
                  outlined
                  dense
                  required
                  type="number"
                  autocomplete="off"
                  hide-details
                  min="0"
                  label="Día límite"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field
                  ref="limit"
                  v-model="company_form.limit"
                  outlined
                  min="0"
                  dense
                  required
                  type="number"
                  autocomplete="off"
                  hide-details
                  label="Límite de mensajes"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-expansion-panels
                  flat
                  multiple
                  v-model="expansion_panel"
                >

                  <v-expansion-panel>
                    <v-expansion-panel-header>Asignar canales</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-combobox
                        v-model="company_form.channel"
                        :items="allChannelList"
                        :search-input.sync="searchChannelQuery"
                        label="Elige uno o varios canales para vincular"
                        outlined
                        no-filter
                        dense
                        multiple
                        chips
                        deletable-chips
                        item-text="name"
                        :item-value="(item) => item"
                      >
                        <template #item="data">
                          <v-list-item-content @click="searchContactQuery = ''">
                            <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              No se encontraron <strong>canales</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-combobox>

                      <v-list
                        v-if="company_form.channel.length"
                        dense
                      >
                        <v-subheader>Canales a asociarse a la empresa:</v-subheader>
                        <v-list-item v-for="(channel, idx) in company_form.channel" :key="`${channel.id}_${idx}`">
                          <v-list-item-content>
                            <v-list-item-title>{{ channel.name }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-btn icon @click="deleteThisChannel(channel.id)">
                              <v-icon color="error" v-text="icons.mdiClose"></v-icon>
                            </v-btn>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list>

                    </v-expansion-panel-content>
                  </v-expansion-panel>



                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Asignar administradores
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                      <v-autocomplete
                        name="company_form_channel"
                        v-model="company_form.users"
                        :items="allUsers"
                        :search-input="searchUser"
                        :hide-no-data="!searchUser"
                        :filter="filterUser"
                        label="Busca usuarios para agregar"
                        hide-details
                        outlined
                        dense
                        multiple
                      >

                        <template v-slot:selection="{ attrs, item, parent, selected }">
                          <v-chip
                            v-if="item === Object(item)"
                            v-bind="attrs"
                            :input-value="selected"
                            label
                            style="display: none"
                          >
                            <span class="pr-2">
                              {{ item.user }}
                            </span>
                            <v-icon
                              @click="parent.selectItem(item)"
                              small
                            >
                              $delete
                            </v-icon>
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-chip
                            label
                          >
                            {{ item.user }}
                          </v-chip>
                        </template>

                      </v-autocomplete>


                      <v-list
                        v-if="company_form.users.length"
                        dense
                      >
                        <v-subheader>Usuarios a asignarse como administradores de la empresa:</v-subheader>
                        <v-list-item v-for="(user, idx) in company_form.users" :key="`${user.id}_${idx}`">
                          <v-list-item-content>
                            <v-list-item-title>{{ user.user }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-btn icon @click="deleteThisUser(user.id)">
                              <v-icon color="error" v-text="icons.mdiClose"></v-icon>
                            </v-btn>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list>


                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel  >
                    <v-expansion-panel-header>Configuración para campañas</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="6" md="0">
                          <v-text-field
                            ref="minim_wait_campaign"
                            v-model="company_form.minim_wait_campaign"
                            :rules="[v => !!v || 'Campo requerido']"
                            outlined
                            dense
                            type="number"
                            autocomplete="off"
                            hide-details
                            min="0"
                            label="Espera mínima para campañas(segundos)"
                            :error="errorMinMaxWait"
                            @change="errorMinMaxWait = false"
                          ></v-text-field>
                          <label class="text-field-label">* Se sugiere que este valor sea mayor a 60 segundos</label>
                        </v-col>
                        <v-col cols="6" md="6">
                          <v-text-field
                            ref="maxim_wait_campaign"
                            v-model="company_form.maxim_wait_campaign"
                            :rules="[v => !!v || 'Campo requerido']"
                            outlined
                            type="number"
                            min="0"
                            dense
                            autocomplete="off"
                            hide-details
                            label="Espera máxima para campañas(segundos)"
                            @change="errorMinMaxWait = false"
                            :error="errorMinMaxWait"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12" class="py-0">
                          <h3 class="text-l font-weight-medium d-flex align-center">
                            Tiempo de descanso
                          </h3>
                        </v-col>
                        <v-col cols="4" md="4">
                          <v-select
                            ref="breaktime_select"
                            class="mb-4"
                            :items="[
                              { name: 'Sin definir', value: 0 },
                              { name: 'Horas', value: 1 },
                              { name: 'Minutos', value: 2 },
                            ]"
                            hide-details="true"
                            v-model="company_form.breaktime_select"
                            label="Tiempo"
                            item-text="name"
                            item-value="value"
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="4" md="4" v-if="company_form.breaktime_select > 0">
                          <v-text-field
                            ref="break_time_min"
                            v-model="company_form.break_time_min"
                            :rules="[validateMinMax(company_form.break_time_min, company_form.break_time_max)]"
                            outlined
                            validate-on-blur
                            type="number"
                            dense
                            autocomplete="off"
                            label="Descanso mínimo"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" md="4">
                          <v-text-field
                            v-if="company_form.breaktime_select > 0"
                            ref="break_time_max"
                            v-model="company_form.break_time_max"
                            :rules="[validateMinMax(company_form.break_time_min, company_form.break_time_max)]"
                            outlined
                            validate-on-blur
                            type="number"
                            dense
                            autocomplete="off"
                            label="Descanso máximo"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12" class="py-0">
                          <h3 class="text-l font-weight-medium d-flex align-center">
                            Cantidad de mensajes a enviar por bloque de trabajo
                          </h3>
                        </v-col>
                        <v-col cols="6" md="6">
                          <v-text-field
                            ref="messages_by_block"
                            v-model="company_form.messages_by_block"
                            outlined
                            :rules="[validateMessagesToBreak]"
                            validate-on-blur
                            type="number"
                            dense
                            autocomplete="off"
                            label="Mensajes a enviar"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-checkbox
                            ref="has_diffusion_time"
                            v-model="company_form.has_diffusion_time"
                            class="mt-0"
                            hide-details
                            label="Tiempo de difusión"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel  >
                    <v-expansion-panel-header>Configuración para filtrado de lotes</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="12" class="py-0">
                          <h3 class="text-l font-weight-medium d-flex align-center">
                            Tiempo desde el último análisis para volver a analizar.
                          </h3>
                        </v-col>
                        <v-col cols="4" md="4">
                          <v-text-field
                            label="Días"
                            type="number"
                            v-model="company_form.limit_check_whatsapp"
                            outlined
                            dense
                            required
                            hide-details
                            @input="error = null"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12" class="py-0">
                          <h3 class="text-l font-weight-medium d-flex align-center">
                            Tiempo de espera entre cada filtrado (en segundos)
                          </h3>
                        </v-col>

                        <v-col cols="4" md="4">
                          <v-text-field
                            ref="minim_wait_filtering"
                            v-model="company_form.minim_wait_filtering"
                            :rules="[validateMinMax(company_form.minim_wait_filtering, company_form.maxim_wait_filtering)]"
                            outlined
                            validate-on-blur
                            type="number"
                            dense
                            autocomplete="off"
                            label="Espera mínima"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" md="4">
                          <v-text-field
                            ref="maxim_wait_filtering"
                            v-model="company_form.maxim_wait_filtering"
                            :rules="[validateMinMax(company_form.minim_wait_filtering, company_form.maxim_wait_filtering)]"
                            outlined
                            validate-on-blur
                            type="number"
                            dense
                            autocomplete="off"
                            label="Espera máxima"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12" class="py-0">
                          <h3 class="text-l font-weight-medium d-flex align-center">
                            Tiempo de descanso entre bloque de filtrado
                          </h3>
                        </v-col>

                        <v-col cols="4" md="4">
                          <v-select
                            ref="break_filtering_unit"
                            class="mb-4"
                            :items="[
                              { name: 'Sin definir', value: 0 },
                              { name: 'Horas', value: 1 },
                              { name: 'Minutos', value: 2 },
                            ]"
                            hide-details="true"
                            v-model="company_form.break_filtering_unit"
                            label="Unidad"
                            item-text="name"
                            item-value="value"
                            outlined
                            dense
                          ></v-select>
                        </v-col>

                        <v-col cols="4" md="4">
                          <v-text-field
                            v-if="company_form.break_filtering_unit > 0"
                            ref="min_break_filter_block"
                            v-model="company_form.min_break_filter_block"
                            :rules="[validateMinMax(company_form.min_break_filter_block, company_form.max_break_filter_block)]"
                            outlined
                            validate-on-blur
                            type="number"
                            dense
                            autocomplete="off"
                            label="Descanso mínimo"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" md="4">
                          <v-text-field
                            v-if="company_form.break_filtering_unit > 0"
                            ref="max_break_filter_block"
                            v-model="company_form.max_break_filter_block"
                            :rules="[validateMinMax(company_form.min_break_filter_block, company_form.max_break_filter_block)]"
                            outlined
                            validate-on-blur
                            type="number"
                            dense
                            autocomplete="off"
                            label="Descanso máximo"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12" class="py-0">
                          <h3 class="text-l font-weight-medium d-flex align-center">
                            Cantidad de contactos a filtrar por bloque
                          </h3>
                        </v-col>
                        <v-col cols="4" md="4">
                          <v-text-field
                            ref="messages_by_block"
                            v-model="company_form.number_filter_block"
                            outlined
                            validate-on-blur
                            type="number"
                            dense
                            autocomplete="off"
                            label="Contactos a filtrar"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

              </v-col>

            </v-row>

        </v-card-text>


        <v-divider></v-divider>
        <v-card-actions>
          <span
            class="error--text error-msg"
            v-if="error_msg"
            >{{ error_msg }}
          </span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">
            CANCELAR
          </v-btn>
          <v-btn color="primary" type="submit" :loading="form_loading">
            {{ editFormID? 'EDITAR EMPRESA' : 'CREAR EMPRESA' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

  <script>
  const debounce = require("debounce");
  import {
    mdiClockTimeFourOutline,
    mdiClose
  } from '@mdi/js'
  import { ref } from '@vue/composition-api'


  export default {
    data: () => {
      return {
        company_form: {
          name: '',
          close: '',
          open: '',
          minim_wait: null,
          maxim_wait: null,
          minim_wait_campaign: null,
          maxim_wait_campaign: null,
          limit_day: null,
          limit: null,
          credits: null,
          hidden_message: false,
          hidden_message_body: null,
          // chat: false,
          channel: [],
          users: [],
          breaktime_select: 0,
          break_time_min: null,
          break_time_max: null,
          break_filtering_unit: 0,
          minim_wait_filtering: null,
          maxim_wait_filtering: null,
          min_break_filter_block:null,
          max_break_filter_block:null,
          number_filter_block: null,
          messages_by_block: null,
          has_diffusion_time: false,
          limit_check_whatsapp: null,
        },
        form_loading: false,
        editFormID: null,
        selectedCompany: [],
        allChannelList: [],
        // allUsersList: [],
        searchChannelQuery: '',
        searchUser: null,
        expansion_panel: [],

        errorMinMaxWait: false,
        open_time: null,
        close_time: null,
        dialog_open_time: false,
        dialog_close_time: false,
        error_msg: null,
      }
    },
    props: {
      allUsers: {
        type: Array,
        required: true
      },
    },
    mounted() {
      // this.getChannelList()
      // this.getUsersForCreateCompany()
    },
    methods: {
      deleteThisUser(user_id) { this.company_form.users = this.company_form.users.filter(user => user.id !== user_id) },
      deleteThisChannel(channel_id) { this.company_form.channel = this.company_form.channel.filter(user => user.id !== channel_id) },
      filter (item, queryText, itemText) {
        const hasValue = val => val != null ? val : ''

        const text = hasValue(item.name)
        const query = hasValue(queryText)

        return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      },

      filterUser (item, queryText, itemText) {
        const hasValue = val => val != null ? val : ''
        const text = hasValue(item.user)
        const query = hasValue(queryText)

        return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      },

      handleChannelSearch: debounce(function (e) {
        this.getChannels();
      }, 300),

      async getChannels() {
        const form = {
        company_id: 'all', //get all channels, for all companies
        page: 1,
        page_size: 10,
        search: this.searchChannelQuery,
        campaign_creation: true, //to get filtered data for each channel
      }
        const channels = await this.$api.getChannels(form);
        this.allChannelList = channels;
      },

      openModal(id_to_edit) {
        this.getChannels()
        id_to_edit
          ? this.setItemToEdit(id_to_edit)
          : this.showingCompanyModal = true
      },

      close() {
        this.resetForm()
        this.error_msg = null
        this.showingCompanyModal = false
      },

      parseHour(hour_string) {
        const parts = hour_string.split(':')
        return parts[0]
      },

      async setItemToEdit(id_to_edit) {
        const item = await this.$api.getCompany(id_to_edit)
        if (item) {
          this.editFormID = item.id

          this.company_form.name = item.name?? null
          this.company_form.open = item.open? this.parseHour(item.open) : null
          this.company_form.close = item.close? this.parseHour(item.close) : null
          this.company_form.minim_wait = item.minim_wait?? null
          this.company_form.maxim_wait = item.maxim_wait?? null
          this.company_form.minim_wait_campaign = item.minim_wait_campaign?? null
          this.company_form.maxim_wait_campaign = item.maxim_wait_campaign?? null
          this.company_form.messages_by_block = item.messages_by_block?? null
          this.company_form.hidden_message = item.hidden_message?? null
          this.company_form.hidden_message_body = item.hidden_message_body?? null
          this.company_form.limit_day = item.limit_day?? null
          this.company_form.limit = item.limit?? null
          this.company_form.breaktime_select = item.break_time_max && item.break_time_min ? 2 : 0
          this.company_form.break_time_max = item.break_time_max ?? null
          this.company_form.break_time_min = item.break_time_min ?? null
          this.company_form.has_diffusion_time = item.has_diffusion_time ?? false
          this.company_form.limit_check_whatsapp = item.limit_check_whatsapp
          this.company_form.min_break_filter_block = item.min_break_filter_block
          this.company_form.max_break_filter_block = item.max_break_filter_block
          this.company_form.minim_wait_filtering = item.minim_wait_filtering
          this.company_form.maxim_wait_filtering = item.maxim_wait_filtering
          this.company_form.number_filter_block = item.numbers_filtered_block
          this.company_form.break_filtering_unit = item.max_break_filter_block && item.min_break_filter_block ? 2 : 0
          // this.company_form.credits = item.credits?? null


          this.company_form.channel = item.channel
          this.company_form.users = item.admin_company.map(el => ({user:el.name, id:el.id}))

          this.expansion_panel = []
          if (this.company_form.channel.length) this.expansion_panel.push(0)
          if (this.company_form.users.length) this.expansion_panel.push(1)
        }

        this.showingCompanyModal = true

      },

      validateMinMax(min_value, max_value) {
        const min = min_value
        const max = max_value

        if (min !== null && min !== "" && max !== null && max !== "") {
          return min !== max || "El mínimo y el máximo no pueden ser iguales";
        }
        return true;
      },

      validateMessagesToBreak(value) {
        if (this.company_form.breaktime_select > 0 && !value) {
          return "Requerido si establece tiempo de descanso";
        }
        return true;
      },
      // async getChannelList() {
      //   const companies = await this.$api.getChannelList()
      //   this.allChannelList = companies
      // },

      // async getUsersForCreateCompany() {
      //   const users = await this.$api.getUsersForCreateCompany()
      //   this.allUsersList = users
      // },

      parseForm() {
        const formParsed = JSON.parse(JSON.stringify(this.company_form))

        formParsed.channel = formParsed.channel.map(el => el.id)
        formParsed.users = formParsed.users.map(el => el.id)
        formParsed.admin_company = formParsed.users
        formParsed.minim_wait = Number(formParsed.minim_wait)
        formParsed.maxim_wait = Number(formParsed.maxim_wait)
        formParsed.minim_wait_campaign = Number(formParsed.minim_wait_campaign)
        formParsed.maxim_wait_campaign = Number(formParsed.maxim_wait_campaign)
        formParsed.credits = Number(formParsed.credits)
        formParsed.limit_day = Number(formParsed.limit_day)
        formParsed.limit = Number(formParsed.limit)
        formParsed.break_unit = Number(formParsed.breaktime_select),
        formParsed.break_time_min = Number(formParsed.break_time_min)
        formParsed.break_time_max = Number(formParsed.break_time_max)
        formParsed.messages_by_block = Number(formParsed.messages_by_block)
        formParsed.limit_check_whatsapp = Number(formParsed.limit_check_whatsapp)
        formParsed.min_break_filter_block = Number(formParsed.min_break_filter_block)
        formParsed.max_break_filter_block = Number(formParsed.max_break_filter_block)
        formParsed.minim_wait_filtering = Number(formParsed.minim_wait_filtering)
        formParsed.maxim_wait_filtering = Number(formParsed.maxim_wait_filtering)
        formParsed.number_filter_block = Number(formParsed.number_filter_block)
        delete formParsed.breaktime_select

        delete formParsed.users
        if (this.editFormID) delete formParsed.credits

        return formParsed
      },

      async createCompany() {

        if (this.$refs.companyForm.validate()) {
          if (Number(this.company_form.minim_wait) >= Number(this.company_form.maxim_wait)) {
            this.errorMinMaxWait = true
            return
          }

          const form = this.parseForm()
          if(!form.break_unit) {
            delete form.break_unit
            delete form.break_time_min
            delete form.break_time_max
          }

          try {
            this.form_loading = true
            const response = this.editFormID
              ? await this.$api.updateCompany(this.editFormID, form)
              : await this.$api.createCompany(form)

            console.log(response)
            console.log(form)

            const companies = await this.$api.getCompaniesList()
            this.$store.dispatch('session/setAllCompaniesList', companies)
            this.$emit('reload-company-list')
            this.close()
          } catch (e) {
            if (e?.response?.status === 403) {
                console.log(e.response.data)
                this.error_msg = e?.response?.data?.message
            }
          } finally {
            this.form_loading = false
          }


        }

      },

      resetForm() {
        this.$refs.companyForm.reset()
        this.company_form.has_diffusion_time = false
        this.editFormID = null
      }
    },
    watch: {
      searchChannelQuery(val) {
        this.handleChannelSearch();
      },
    },
    computed: {
      getUserRole() { return this.$store.getters['session/getUserRole']() },
      hours_selection() { return ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'] },
    },
    setup() {
      const name = ref('')
      const open = ref('')
      const showingCompanyModal = ref(false)

      return {
        showingCompanyModal,
        name,
        open,
        prefix: 549,
        icons: {
          mdiClose,
          mdiClockTimeFourOutline
        },
      }
    },
  }
  </script>

  <style scoped>
  .text-field-label {
    margin-left: 12px;
    font-size: 12px;
  }
  </style>
