<template>
  <div>
    <v-dialog v-model="showingImportContactModal" width="auto" max-width="700" @input="close">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Importar lote de filtrado
          <!-- Seleccione un archivo .csv o .xls para importar contactos -->
        </v-card-title>

        <v-card-text class="pb-0">
          <v-text-field
            label="Nombre del lote"
            type="text"
            v-model="batchName"
            :rules="[(v) => !!v || 'Campo requerido']"
            outlined
            dense
            required
            @input="error = null"
          ></v-text-field>

          <v-combobox
            v-model="batch_channels"
            :items="channelSelectData"
            :search-input.sync="searchChannelQuery"
            no-filter
            :rules="[v => !!v || 'Campo requerido']"
            label="Seleccione uno o varios canales"
            outlined
            chips
            deletable-chips
            item-disabled="disabled"
            item-text="name"
            :item-value="(item) => item"
            dense
            persistent-hint
            hint="* Si no puede seleccionar algún canal, revise el estado y configuración de ese canal"
            multiple
          >
            <template #item="data">
              <v-list-item-content @click="searchChannelQuery = ''">
                <v-list-item-title >{{ data.item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  No se encontraron <strong>Canales</strong>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-combobox>

          <v-file-input
            v-model="contactFile"
            @change="validateFile"
            webkitRelativePath
            accept=".csv, .xls, .xlsx"
            ref="contact_file_input"
            :rules="[(v) => !!v || 'Campo requerido']"
            label="Seleccione un archivo..."
            hint="Seleccione un archivo .csv o .xls para importar contactos"
            persistent-hint
          ></v-file-input>

          <v-row class="mt-3">
            <!--Date picker-->
            <v-col cols="12" md="12" class="pt-0">
              <h3 class="text-l font-weight-medium d-flex align-center">
                <v-icon class="mr-2">{{ icons.mdiCalendarMonth }}</v-icon>
                Fecha de inicio
              </h3>
            </v-col>
            <v-col cols="8" md="8">
              <v-text-field
                label="Fecha"
                type="date"
                v-model="filter_date"
                :rules="[(v) => !!v || 'Campo requerido']"
                :min="minDate"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-3">
            <!--Send times-->
            <v-col cols="12" md="12" class="pb-0">
              <h3 class="text-l font-weight-medium d-flex align-center">
                <v-icon class="mr-2">{{ icons.mdiClockTimeSevenOutline }}</v-icon>
                Días y horario de filtrado
              </h3>
            </v-col>
            <v-col cols="12" md="12">
              <v-list flat>
                <v-list-item-group multiple>
                  <v-list-item
                    v-for="(item, i) in itemsDays"
                    :key="i"
                    :value="item"
                    class="d-flex"
                  >
                    <v-list-item-action>
                      <v-checkbox v-model="item.active" color="primary"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.day }}</v-list-item-title>
                    </v-list-item-content>
                    <div class="d-flex mt-4" style="width: 50%">
                      <v-menu
                        ref="time_start_refs"
                        v-model="item.timepicker_menu_start"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="item.send_time_start"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        :disabled="!item.active"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.send_time_start"
                            label="Desde"
                            :prepend-icon="icons.mdiClockTimeFourOutline"
                            :rules="[(v) => !!v || 'Campo requerido']"
                            :disabled="!item.active"
                            outlined
                            dense
                            required
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="item.timepicker_menu_start"
                          v-model="item.send_time_start"
                          full-width
                          format="24hr"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            outlined
                            color="primary"
                            @click="$refs.time_start_refs[i].save(item.send_time_start)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-menu>
                      <v-menu
                        ref="time_end_refs"
                        v-model="item.timepicker_menu_end"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="item.send_time_end"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        :disabled="!item.active"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.send_time_end"
                            label="Hasta"
                            :prepend-icon="icons.mdiClockTimeFourOutline"
                            :rules="[(v) => !!v || 'Campo requerido']"
                            :disabled="!item.active"
                            outlined
                            dense
                            required
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="item.timepicker_menu_end"
                          v-model="item.send_time_end"
                          full-width
                          format="24hr"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.time_end_refs[i].save(item.send_time_end)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-menu>
                    </div>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <span class="error--text error-msg" v-if="days_checkbox_msg">{{
                days_checkbox_msg
              }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <div class="d-flex justify-content-center mt-2">
          <span class="error_msg mx-2">
            {{ error }}
          </span>
        </div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="close()"> CANCELAR </v-btn>
          <v-btn
            ref="send-btn"
            color="success"
            @click="importContact()"
            :disabled="error !== null || contactFile === null || importLoading || !batch_channels.length || !batchName"
          >
            {{ importLoading ? 'importando ' : 'importar' }}
            <v-progress-circular v-if="importLoading"
              :size="25"
              class="ms-2"
              indeterminate
              color="secondary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
const debounce = require("debounce");
import {
  mdiClockTimeSevenOutline,
  mdiClockTimeFourOutline,
  mdiCalendarMonth
} from '@mdi/js'

export default {
  data: () => {
    return {
      showingImportContactModal: false,
      batchName: "",
      contactFile: null,
      error: null,
      importLoading: false,
      channelSelectData: [],
      searchChannelQuery: "",
      batch_channels: [],
      itemsDays: [],
      days_checkbox_msg: null,
      checkDays: false,
      filter_date: null,
      minDate: null,
    };
  },

  computed: {
    getUserRole() {
      return this.$store.getters["session/getUserRole"]();
    },
    getSelectedCompany() {
      return this.$store.getters["session/getSelectedCompany"]();
    },
  },

  methods: {
    open() {
      this.setMinDate();
      this.setChannelsData()
      this.itemsDays = this.defaultItemsDays();
      this.showingImportContactModal = true;
    },

    setMinDate() {
      const date = new Date();
      this.minDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];

      this.filter_date = this.minDate
    },

    async setChannelsData(){
      const form = {
        page: 1,
        page_size: 10,
        search: this.searchChannelQuery,
        company_id: 'all'
        
      }

      const filters = {
          status: 1,
          enabled: 0
        }

      try {
        const response = await this.$api.getChannels(form, filters)

        this.channelSelectData = response.results
        this.channelSelectData.forEach(el => {
          if(!el.enabled) {
            el.disabled = true
          }
        })
      } catch (error) {
        console.log(error);
      }
    },

    handleChannelSearch: debounce(function (e) {
      this.setChannelsData()
    }, 400),

    close() {
      this.contactFile = null;
      this.batchName = '';
      this.batch_channels = []
      this.error = null;
      this.searchChannelQuery = ""
      this.days_checkbox_msg = null;
      this.itemsDays = this.defaultItemsDays;
      this.minDate = null;
      this.checkDays = false
      this.showingImportContactModal = false;
    },

    async importContact() {
      this.importLoading = true
      this.days_checkbox_msg = null;
      this.checkDays = this.itemsDays.some((item) => item.active);
      if (!this.checkDays) {
        this.days_checkbox_msg = "Es requerido seleccionar un día como mínimo.";
      } else {
        try {
          const formData = new FormData();
  
          const itemsDateTime = this.itemsDays
          .filter((item) => item.active === true)
          .map((item) => ({
            number: item.number,
            send_time_start: item.send_time_start,
            send_time_end: item.send_time_end,
          }));
          let channels_ids = this.batch_channels.map((el) => el.id)
  
          formData.append("csv_file", this.contactFile);
          formData.append("name", this.batchName);
          formData.append("channels", channels_ids);
          formData.append("company_id", this.getSelectedCompany);
          formData.append("date_time", JSON.stringify(itemsDateTime));
          formData.append("start_date", this.filter_date)
  
          if(formData.entries().next().done) {
            this.$emit("display-alert", { message: 'No se pueden crear lotes en este momento. Revise su conexión a internet', type: 'error' });
          } else {
            const response = await this.$api.importFilterBatch(formData);
            if (response.status >= 200 && response.status < 300) {
              this.$emit("reload-batches-list");
              this.$emit("display-alert", { message: 'Lote importado correctamente', type: 'success' });
              this.close()
            }
          }
        } catch (e) {
          console.log(e.response?.data)
          e.response?.data?.error
            ? (this.error = e.response.data.error)
            : e.response?.data?.channels && e.response.data.channels.length 
            ? (this.error = e.response.data.channels[0])
            : e.response?.data?.company && e.response.data.company.length 
            ? (this.error = e.response.data.company[0])
            :e.response?.data?.message
            ? (this.error = e.response.data.message)
            : (this.error = 'El lote no pudo ser importado. Por favor intente de nuevo más tarde.');
        } finally {
          this.importLoading = false
        }
      }
    },

    defaultItemsDays() {
      return [
        {
          day: "Lunes",
          number: 0,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: true,
        },
        {
          day: "Martes",
          number: 1,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: true,
        },
        {
          day: "Miércoles",
          number: 2,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: true,
        },
        {
          day: "Jueves",
          number: 3,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: true,
        },
        {
          day: "Viernes",
          number: 4,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: true,
        },
        {
          day: "Sábado",
          number: 5,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: false,
        },
        {
          day: "Domingo",
          number: 6,
          send_time_start: "09:00",
          send_time_end: "20:00",
          timepicker_menu_start: false,
          timepicker_menu_end: false,
          active: false,
        },
      ];
    },
    validateFile(file) {
      let allowedExtensions = /(\.csv|\.xls|\.xlsx)$/i;
      if (!!file && !allowedExtensions.exec(file.name)) {
        this.error = "Sólo se permiten archivos .csv, .xls o .xlsx";
      } else {
        this.error = null;
      }
    },
  },
  watch: {
    searchChannelQuery(val) {
      if(val !== null){
        // console.log('handle search: ', val);
        this.handleChannelSearch();
      }
    },
  },
  mounted() {
    this.itemsDays = this.defaultItemsDays;
  },
  setup() {
    return {
      icons: {
        mdiClockTimeSevenOutline,
        mdiClockTimeFourOutline,
        mdiCalendarMonth
      }
    }
  }
};
</script>
<style scoped>
.error_msg {
  color: red;
  margin-left: 25px;
}
</style>
