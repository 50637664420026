<template>
  <v-form ref="eventForm" @submit.prevent="sendEvent">
    <v-col cols="12" md="12" class="ps-0">
      <v-text-field
        ref="event_name_ref"
        v-model="event_name"
        :rules="[(v) => !!v || 'Campo requerido']"
        outlined
        dense
        autocomplete="off"
        hide-details
        label="Nombre del evento de Campaña"
      />
    </v-col>
    <v-row class="mb-3">
      <v-col cols="12" md="12" class="py-0 mt-6">
        <h3 class="text-l font-weight-medium d-flex align-center">
          <v-icon class="mr-2">{{ icons.mdiAccountBox }}</v-icon>
          Seleccionar contactos
          <v-tooltip right v-if="radio_option === 'tag_option'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-0" color="primary" dense v-bind="attrs" v-on="on">
                {{ icons.mdiInformationOutline }}
              </v-icon>
            </template>
            <span>Solo se muestran los TAGs con contactos asociados.</span>
          </v-tooltip>
        </h3>
      </v-col>
      <v-col cols="12" md="12" class="py-0">
        <v-radio-group
          ref="radio_group"
          class="mt-2"
          v-model="radio_option"
          :rules="[(v) => !!v || 'Campo requerido']"
          @change="resetRadioOptions()"
          row
        >
          <v-radio label="Por Tag" value="tag_option"></v-radio>
          <v-radio label="Por Lote" value="batch_option"></v-radio>
        </v-radio-group>
  
        <!--Tag option-->
        <v-col
          cols="12"
          md="12"
          class="pt-0 pb-0"
          v-if="radio_option === 'tag_option'"
        >
          <v-combobox
            v-model="campaign_tag"
            :items="tagsSelectData"
            no-filter
            :search-input.sync="searchQuery"
            :rules="[(v) => !!v || 'Campo requerido']"
            label="Seleccione uno o varios tags"
            outlined
            chips
            deletable-chips
            item-text="name"
            :item-value="(item) => item"
            dense
            multiple
          >
            <template #item="data">
              <v-list-item-content @click="searchQuery = ''">
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
  
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  No se encontraron <strong>Tags</strong>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-combobox>
          <span
            class="error--text error-msg"
            v-if="campaign_msg && radio_option === 'tag_option'"
            >{{ campaign_msg }}</span
          >
        </v-col>
  
        <!--Batch option-->
        <v-col
          cols="12"
          md="12"
          class="pt-0 pb-0"
          v-if="radio_option === 'batch_option'"
        >
          <v-combobox
            v-model="campaign_batch"
            :items="batchsSelectData"
            :search-input.sync="searchQuery"
            no-filter
            :rules="[(v) => !!v || 'Campo requerido']"
            label="Seleccione uno o varios Lotes de contactos"
            outlined
            chips
            deletable-chips
            item-text="name"
            :item-value="(item) => item"
            dense
            multiple
          >
            <template #item="data">
              <v-list-item-content @click="searchQuery = ''">
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
  
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  No se encontraron <strong>Lotes</strong>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-combobox>
          <span
            class="error--text error-msg"
            v-if="campaign_msg && radio_option === 'batch_option'"
            >{{ campaign_msg }}</span
          >
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" md="4">
        <v-text-field
          ref="date_ref"
          label="Fecha del evento"
          type="date"
          v-model="event_date"
          :rules="[(v) => !!v || 'Campo requerido']"
          outlined
          dense
          required
        ></v-text-field>
      </v-col>
  
      <v-col cols="4" md="4">
        <v-menu
          ref="timepicker_ref"
          v-model="timepicker_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="event_time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              ref="time_ref"
              v-model="event_time"
              label="Hora del evento"
              :prepend-inner-icon="icons.mdiClockTimeFourOutline"
              :rules="[(v) => !!v || 'Campo requerido']"
              outlined
              dense
              required
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="timepicker_menu"
            v-model="event_time"
            full-width
            format="24hr"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              outlined
              color="primary"
              @click="$refs.timepicker_ref.save(event_time)"
            >
              OK
            </v-btn>
          </v-time-picker>
        </v-menu>
      </v-col>
  
      <v-col cols="12" md="12" >
        <v-textarea
          ref="description_ref"
          :prepend-inner-icon="icons.mdiMessageBulleted"
          v-model.trim="event_description"
          counter="500"
          maxlength="500"
          placeholder="Descripción"
          outlined
          dense
          hint=""
          label="Descripción del evento"
        ></v-textarea>
      </v-col>
  
      <v-col cols="6" md="6" >
        <v-switch
          v-model="event_reminder"
          :true-value="true"
          :false-value="false"
          hide-details
          label="Recordar antes del evento"
        ></v-switch>
      </v-col>

      <v-col v-if="event_reminder" cols="6" md="6">
        <v-text-field
          ref="minutes_ref"
          v-model="event_minutes"
          outlined
          :rules="[v => !!v || 'Campo requerido']"
          type="number"
          dense
          autocomplete="off"
          hide-details
          label="Tiempo antes a recordar el evento"
        ></v-text-field>
        <span class="ml-3">En minutos</span>
      </v-col>
  
      <v-col cols="6" md="6" >
        <v-switch
          v-model="event_notification"
          :true-value="true"
          :false-value="false"
          hide-details
          label="Enviar notificación por email del evento"
        ></v-switch>
      </v-col>
  
      
    </v-row>

  </v-form>
</template>

<script>
const debounce = require("debounce");
import { ref } from "@vue/composition-api";

import {
  mdiMessageBulleted,
  mdiClockTimeFourOutline,
  mdiAccountBox,
  mdiClockTimeSevenOutline,
  mdiCalendarMonth,
  mdiPlusCircleOutline,
  mdiMinusCircleOutline,
  mdiInformationOutline,
  mdiCellphoneWireless
} from "@mdi/js";

export default {
  data:() => {
    return {
      event_name: "",
      radio_option: null,
      searchQuery: "",
      tagsSelectData: [],
      batchsSelectData: [],
      campaign_tag: [],
      campaign_batch: [],
      campaign_msg: null,
      event_date: null,
      event_time: null,
      event_description: null,
      event_notification: false,
      event_reminder: false,
      event_minutes: null,
      timepicker_menu: false,
    }
  },
  methods: {
    resetRadioOptions() {
      if(this.radio_option === 'batch_option') this.setBatchsData()
      if(this.radio_option === 'tag_option') this.setTagsData()
      this.tagsSelectData = [];
      this.batchsSelectData = [];
    },
    reset() {
      this.$refs.date_ref.reset()
      this.$refs.time_ref.reset()
      this.$refs.event_name_ref.reset()
      this.$refs.description_ref.reset()
      if(this.event_reminder) {
        this.$refs.minutes_ref.reset()
      }
      this.event_notification = false
      this.event_reminder = false
      this.resetRadioOptions()
      this.$refs.radio_group.reset()
    },
    async setTagsData() {
      const query = {
        page: 1,
        page_size: 10,
        search: this.searchQuery,
        company_id: this.getSelectedCompany,
      };
      if (this.getSelectedCompany) {
        const response = await this.$api.getTags(query);
        console.log(response);
        this.tagsSelectData = response.results.filter((el) => el.contacts > 0);
        // this.campaign_tag = []
      }
    },
    async setBatchsData() {
      const query = {
        company_id: this.getSelectedCompany,
        page: 1,
        page_size: 10,
        search: this.searchQuery,
      };
      if (this.getSelectedCompany) {
        const response = await this.$api.getContactBatches(query);
        this.batchsSelectData = response.results;
        // this.campaign_tag = []
      }
    },

    async sendEvent() {
      this.campaign_msg = null;

      if (!this.campaign_tag.length && this.radio_option === "tag_option") {
        this.campaign_msg = "Es requerido seleccionar un tag como mínimo.";
      }

      if (!this.campaign_batch.length && this.radio_option === "batch_option") {
        this.campaign_msg = "Es requerido seleccionar un lote como mínimo.";
      }

      if (this.$refs.eventForm.validate() && !this.campaign_msg) {
        const form = this.generateEventForm()

        this.$emit('send-event-order', form)
      }
      console.log('Evento')
    },

    generateEventForm() {
      let form = {
        campaign: {
          company: this.getSelectedCompany,
        },
        event: {
          datetime: `${this.event_date}T${this.event_time}:00-0300`,
          description: this.event_description,
          notify: this.event_notification,
          remind: this.event_reminder,
          name: this.event_name,
        }
      }

      if(this.event_reminder) {
        form.event.remind_minutes = this.event_minutes
      }

      if(this.radio_option === 'batch_option') {
        form.campaign.batches = this.campaign_batch.map((item) => item.id)
      }

      if(this.radio_option === 'tag_option') {
        form.campaign.tags = this.campaign_tag.map((item) => item.id)
      }

      return form
    },
  },
  mounted() {
    this.setBatchsData(),
    this.setTagsData()
  },
  computed: {
    getSelectedCompany() {
      return this.$store.getters["session/getSelectedCompany"]();
    },
  },
  setup() {
    return {
      icons: {
        mdiAccountBox,
        mdiInformationOutline,
        mdiClockTimeFourOutline,
        mdiMessageBulleted
      }
    }
  }
}
</script>